import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import HubModalLayout from '../../HubModalLayout/HubModalLayout';
import Button, { buttonTypes } from '../../Button/Button';
import styles from './PlacementTestOnboardingModal.scss';
import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import { closePlacementTestOnboardingWizard } from '../../../redux/actions/hubUi';
import {
  clearWizardState,
  createPlacementTestSessionRequest,
  updatePlacementTestSessionRequest
} from '../../../redux/actions/placementTestOnboardingWizard';
import { closeForm } from '../../../redux/reducers/placementTestSessionCreate.js';
import { refreshPlacementTestList } from '../../../redux/actions/placementTests';
import withLocalizedContent from '../../../language/withLocalizedContent';

function PlacementTestOnboardingModal({
  isOpen,
  closeWarningModal,
  closeWizardAction,
  clearWizardStateAction,
  clearPlacementTestSessionCreateStateAction,
  editedPlacementTest,
  userId,
  orgId,
  placementTestSessionNameValue,
  oldPlacementTest,
  createPlacementTestSessionRequestAction,
  refreshPlacementTestListAction,
  placementTestSessionIdCreated,
  placementTestSessionId,
  updatePlacementTestSessionRequestAction,
  placementTestSessionUpdated,
  localizedContent: { placementTests: placementTestsContent, hubGlossary: hubContent }
}) {
  const [loading, setLoading] = useState(false);

  const addOldTestExistingFields = (oldTest, editedTest) => {
    const existingFields = {
      ...(oldTest?.platform ? { platform: oldTest.platform } : {}),
      ...(oldTest?.languageVariationCode ? { languageVariationCode: oldTest.languageVariationCode } : {}),
      ...(oldTest?.listeningAccentsCode ? { placementTestType: oldTest.listeningAccentsCode } : {}),
      ...(oldTest?.listeningAccentsCode ? { listeningAccentsCode: oldTest.listeningAccentsCode } : {}),
      ...(oldTest?.productId ? { productId: oldTest.productId } : {}),
      ...(oldTest?.isbn ? { isbn: oldTest.isbn } : {}),
      ...(oldTest?.externalProductId ? { externalProductId: oldTest.externalProductId } : {}),
      ...(oldTest?.testStartDate ? { testStartDate: oldTest.testStartDate } : {}),
      ...(oldTest?.testEndDate ? { testEndDate: oldTest.testEndDate } : {}),
      ...(oldTest?.extraTimePerItem ? { extraTimePerItem: oldTest.extraTimePerItem } : { extraTimePerItem: 0 }),
      ...(oldTest?.numberOfLicenses ? { numberOfLicenses: oldTest.numberOfLicenses } : {}),
      ...(oldTest?.studentsLimit ? { studentsLimit: oldTest.studentsLimit } : {}),
      ...(oldTest?.notificationReminders ? { notificationReminders: oldTest.notificationReminders } : {}),
      ...(oldTest?.sendEmailsToStudents ? { sendEmailsToStudents: oldTest.sendEmailsToStudents } : {}),
      ...(oldTest?.showResultsToStudents ? { showResultsToStudents: oldTest.showResultsToStudents } : {})
    };
    return { ...existingFields, ...editedTest };
  };

  const newlyGeneratedTest = addOldTestExistingFields(oldPlacementTest, editedPlacementTest);
  const sessionId = placementTestSessionId || placementTestSessionIdCreated;

  const handleClick = () => {
    setLoading(true);
    if (sessionId) {
      updatePlacementTestSessionRequestAction({
        orgId,
        sessionId,
        placementTestSessionNameValue,
        placementTest: {
          ...newlyGeneratedTest,
          status: 'DRAFT'
        }
      });
    } else {
      createPlacementTestSessionRequestAction({
        userId,
        orgId,
        placementTestSessionNameValue,
        placementTest: {
          ...newlyGeneratedTest,
          extraTimePerItem: !editedPlacementTest?.extraTimePerItem ? 0 : editedPlacementTest.extraTimePerItem,
          status: 'DRAFT'
        }
      });
    }
  };

  useEffect(() => {
    if (loading && (placementTestSessionIdCreated || placementTestSessionUpdated)) {
      closeWarningModal();
      closeWizardAction();
      clearWizardStateAction();
      clearPlacementTestSessionCreateStateAction();
      refreshPlacementTestListAction();
    }
    setLoading(false);
  }, [placementTestSessionIdCreated, placementTestSessionUpdated]);

  return (
    <div className={`${styles.outerWizardWarningModal} ${isOpen ? styles.open : ''}`}>
      <HubModalLayout isOpen={isOpen} closeAction={closeWarningModal} ariaLabel={hubContent.warning_wizard_arialabel}>
        <div
          data-testid="PLACEMENT_TEST_ONBOARDING_WIZARD_WARNING_MODAL"
          className={`${styles.modalContainer} ${isOpen ? styles.animationFadeIn : ''}`}
          aria-hidden={!isOpen}
        >
          <div className={styles.modalHeader}>
            <Button
              dataTestId="PLACEMENT_TEST_ONBOARDING_WIZARD_WARNING_MODAL_CLOSE_BUTTON"
              text={hubContent.close}
              type={buttonTypes.CLOSE_BORDER_GRAY}
              onClick={e => {
                e.stopPropagation();
                closeWarningModal();
              }}
            />
          </div>

          <div className={styles.modalContent}>
            <p className={styles.title}>{placementTestsContent.warning_modal_title_opt}</p>
            <p
              className={styles.subtitle}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: placementTestsContent.loose_changes_warning_modal_subtext }}
            />
          </div>

          <div className={styles.modalFooter}>
            <Button
              dataTestId="PLACEMENT_TEST_ONBOARDING_WIZARD_WARNING_MODAL_CANCEL_BUTTON"
              text={hubContent.cancel}
              type={buttonTypes.CANCEL}
              onClick={() => closeWarningModal()}
            />
            <Button
              customClassName={`${animationStyles.nextButton} ${loading ? animationStyles.loading : ''}`}
              dataTestId="PLACEMENT_TEST_ONBOARDING_WIZARD_WARNING_MODAL_CONFIRM_BUTTON"
              text={!loading ? hubContent.yes : ''}
              type={buttonTypes.PRIMARY}
              onClick={handleClick}
              disabled={loading}
            />
          </div>
        </div>
      </HubModalLayout>
    </div>
  );
}
PlacementTestOnboardingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeWarningModal: PropTypes.func.isRequired,
  closeWizardAction: PropTypes.func.isRequired,
  clearWizardStateAction: PropTypes.func.isRequired,
  clearPlacementTestSessionCreateStateAction: PropTypes.func.isRequired,
  editedPlacementTest: PropTypes.object,
  userId: PropTypes.string,
  orgId: PropTypes.string,
  placementTestSessionNameValue: PropTypes.string,
  createPlacementTestSessionRequestAction: PropTypes.func,
  oldPlacementTest: PropTypes.object,
  refreshPlacementTestListAction: PropTypes.func,
  placementTestSessionIdCreated: PropTypes.bool,
  placementTestSessionId: PropTypes.string,
  updatePlacementTestSessionRequestAction: PropTypes.func,
  placementTestSessionUpdated: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('hubGlossary', 'placementTests'),
  connect(
    ({ placementTestSessionCreate, identity, placementOnboardingWizard }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      oldPlacementTest: placementTestSessionCreate.placementTest,
      userId: identity.userId,
      orgId: identity.currentOrganisationId,
      editedPlacementTest: placementTestSessionCreate.editedPlacementTest,
      placementTestSessionIdCreated: placementOnboardingWizard.placementTestSessionIdCreated,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      placementTestSessionUpdated: placementOnboardingWizard.placementTestSessionUpdated
    }),
    {
      clearWizardStateAction: clearWizardState,
      clearPlacementTestSessionCreateStateAction: closeForm,
      closeWizardAction: closePlacementTestOnboardingWizard,
      createPlacementTestSessionRequestAction: createPlacementTestSessionRequest,
      refreshPlacementTestListAction: refreshPlacementTestList,
      updatePlacementTestSessionRequestAction: updatePlacementTestSessionRequest
    }
  )
)(PlacementTestOnboardingModal);
