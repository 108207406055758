import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useWizard } from '@oup/shared-front-end/src/components/Wizard';
import WizardStep from '@oup/shared-front-end/src/components/Wizard/WizardStep';
import ValidationMessage from '@oup/shared-front-end/src/components/ValidationMessage';

import Toggle from '@oup/shared-front-end/src/components/Toggle';
import LockIcon from '@oup/shared-front-end/src/svg/oup/icon-close-24.svg';
import { setShowResultsToStudents, setEditedPlacementTest } from '../../../redux/reducers/placementTestSessionCreate';
import classStyles from '../CreateAPlacementTest/CreateAPlacementTest.scss';
import style from './PlacementTestShowResults.scss';
import wizardStyles from '../PlacementTestOnboardingWizard.scss';
import animationStyles from '../../ClassOnboardingWizard/animationUtils/animationStyles.scss';
import { getPlacementTestSessionRequest } from '../../../redux/actions/placementTestOnboardingWizard';
import { refreshPlacementTestList } from '../../../redux/actions/placementTests';
import withLocalizedContent from '../../../language/withLocalizedContent';

function PlacementTestShowResults({
  placementTestSessionNameValue,
  editedPlacementTest,
  orgId,
  placementTestSessionIdCreated,
  placementTestSessionNamePending,
  getPlacementTestSessionRequestAction,
  placementTestSessionId,
  setShowResultsToStudentsAction,
  setEditedPlacementTestAction,
  showResultsToStudents,
  refreshPlacementTestListAction,
  placementTestSessionUpdated,
  localizedContent: { placementTests: placementTestsContent }
}) {
  const { isLoading, nextStep, setIsPrimaryButtonDisabled } = useWizard();
  const loading = placementTestSessionNamePending ? animationStyles.loading : '';

  setIsPrimaryButtonDisabled(isLoading || loading);

  const sessionId = placementTestSessionIdCreated || placementTestSessionId;

  useEffect(() => {
    if (placementTestSessionUpdated) {
      getPlacementTestSessionRequestAction({ orgId, sessionId });
      nextStep();
      refreshPlacementTestListAction();
    }
  }, [placementTestSessionUpdated]);

  useEffect(() => {
    if (!showResultsToStudents && editedPlacementTest?.placementTestType === 'OPT_YL') {
      setShowResultsToStudentsAction(false);
    } else {
      setShowResultsToStudentsAction(true);
    }
  }, []);

  const onToggle = () => setEditedPlacementTestAction({ showResultsToStudents: !showResultsToStudents });

  const ToggleComponent = useCallback(
    () => <Toggle toggle={onToggle} icon={LockIcon} toggled={showResultsToStudents} />,
    [showResultsToStudents]
  );

  return (
    <div data-testid="ONBOARDING_WIZARD_CREATE_A_PLACEMENT_TEST_SESSION_SHOW_RESULTS_CONTAINER">
      <WizardStep
        titleText={`${placementTestsContent.placement_test_show_results_page_title} ${placementTestSessionNameValue ||
          placementTestsContent.placement_test_notification_page_session_name_fallback}`}
      >
        <div className={`${wizardStyles.stepContent} ${classStyles.placementTestCreateContainer}`}>
          <div className={`${wizardStyles.contentCentered} ${style.showTestResultsColumn}`}>
            <div className={style.customiseToggle}>
              <ToggleComponent />
              <span className={style.showResultsToStudents}>
                {placementTestsContent.placement_test_show_results_page_toggle_label}
              </span>
            </div>
            <div className="gin-top6">
              <ValidationMessage state="information">
                <div className={style.noteText}>{placementTestsContent.placement_test_show_results_note_text}</div>
              </ValidationMessage>
            </div>
          </div>
        </div>
      </WizardStep>
    </div>
  );
}

PlacementTestShowResults.propTypes = {
  editedPlacementTest: PropTypes.object,
  orgId: PropTypes.string,
  placementTestSessionNameValue: PropTypes.string,
  placementTestSessionNamePending: PropTypes.bool,
  placementTestSessionIdCreated: PropTypes.bool,
  getPlacementTestSessionRequestAction: PropTypes.func,
  setEditedPlacementTestAction: PropTypes.func,
  placementTestSessionId: PropTypes.string,
  setShowResultsToStudentsAction: PropTypes.func,
  showResultsToStudents: PropTypes.bool,
  refreshPlacementTestListAction: PropTypes.func,
  placementTestSessionUpdated: PropTypes.bool,
  localizedContent: PropTypes.object.isRequired
};

export default compose(
  withLocalizedContent('placementTests'),
  connect(
    ({ placementTestSessionCreate, placementOnboardingWizard, identity }) => ({
      placementTestSessionNameValue: placementTestSessionCreate.placementTestSessionNameValue,
      placementTestSessionNamePending: placementOnboardingWizard.placementTestSessionNamePending,
      placementTestSessionUpdated: placementOnboardingWizard.placementTestSessionUpdated,
      userId: identity.userId,
      orgId: identity.currentOrganisationId,
      editedPlacementTest: placementTestSessionCreate.editedPlacementTest,
      placementTestSessionIdCreated: placementOnboardingWizard.placementTestSessionIdCreated,
      placementTestSessionId: placementTestSessionCreate.placementTestSessionId,
      showResultsToStudents: placementTestSessionCreate.placementTest.showResultsToStudents
    }),
    {
      getPlacementTestSessionRequestAction: getPlacementTestSessionRequest,
      setShowResultsToStudentsAction: setShowResultsToStudents,
      refreshPlacementTestListAction: refreshPlacementTestList,
      setEditedPlacementTestAction: setEditedPlacementTest
    }
  )
)(PlacementTestShowResults);
